import React from 'react';
import { graphql, PageProps } from 'gatsby';
import PageHeader from '../../components/PageHeader/PageHeader';
import GuideBlocks from '../../components/GuideBlocks/GuideBlocks';
import { getArticles, getFeaturedImageDataFromWpPost } from '../../utils';
import PaginationV2 from '../../components/PaginationV2/PaginationV2';
import Seo from '../../components/Seo';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IMonthArchiveTemplateProps {
  posts: Queries.WpPostConnection;
}

const MonthArchiveTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IMonthArchiveTemplateProps>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  const basePath = (pageContext as any)?.uri || '/';
  const title = (pageContext as any)?.name;
  let posts: Queries.WpPost[] = [];
  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const articles = getArticles(posts);
  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;

  return (
    <HTPageWrapper category={HT_PAGE_CATEGORY_ARCHIVE_PAGE} title={title} location={location}>
      <PageHeader
        showGraphic
        title={title}
        breadCrumbs={[
          {
            text: 'Home',
            link: '/',
          },
        ]}
      />
      <GuideBlocks articles={articles} variant="columns" />
      {totalNumberOfPages > 0 && (
        <PaginationV2
          currentPageIndex={currentPageIndex}
          basePath={basePath}
          pageSize={MAX_NUMBER_OF_POSTS_PER_PAGE}
          numberOfPages={totalNumberOfPages + 1}
        />
      )}
    </HTPageWrapper>
  );
};

export default MonthArchiveTemplate;

export const Head = ({ pageContext, data }: PageProps<IMonthArchiveTemplateProps>) => {
  const firstPost = data?.posts?.nodes[0];
  const pageNumber = (pageContext as any)?.pageNumber;
  const titlePrefix = (pageContext as any).name;
  const uriPrefix = (pageContext as any)?.uri;
  const pageSuffix = pageNumber ? `page/${pageNumber}/` : '';
  const seo: any = {};
  seo.canonical = uriPrefix + pageSuffix;
  seo.opengraphUrl += pageSuffix;
  const title = pageNumber
    ? `${titlePrefix} - Page ${pageNumber} of ${
        (pageContext as any)?.totalNumberOfPages
      } - UpgradedPoints.com`
    : `${titlePrefix} - UpgradedPoints.com`;
  const yoastSchemaGraph = seo?.schema?.raw && JSON.parse(seo.schema.raw);
  const breadcrumbId = uriPrefix + pageSuffix + '#breadcrumb';
  if (yoastSchemaGraph) {
    yoastSchemaGraph['@graph'].forEach((element: any) => {
      if (element['@type'] === 'CollectionPage') {
        const primaryImageId = uriPrefix + pageSuffix + '#primaryimage';
        element['url'] += pageSuffix;
        element['name'] = title;
        element['primaryImageOfPage'] = { '@id': primaryImageId };
        element['image'] = { '@id': primaryImageId };
        if (element['breadcrumb']) {
          element['breadcrumb']['@id'] = breadcrumbId;
        }
      }
      if (element['@type'] === 'BreadcrumbList') {
        element['@id'] = breadcrumbId;
      }
    });
    const newYoastSchemaGraph = JSON.stringify(yoastSchemaGraph);
    seo.schema.raw = newYoastSchemaGraph;
  }
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={title}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      isNewsListingPage={uriPrefix === '/news/'}
    />
  );
};

export const query = graphql`
  query MonthArchivePosts($start: Int, $startDate: Date, $endDate: Date) {
    posts: allWpPost(
      filter: { dateGmt: { gte: $startDate, lte: $endDate } }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        dateGmt
        excerpt
        author {
          node {
            uri
            name
          }
        }
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;
